import { RADIO_STATION_SET, RADIO_VOLUME_SET, RADIO_PLAYING_SET, RADIO_NOW_PLAYING_SET } from '../constants';

const initialState = {
    station: 'Classic',
    isPlaying: false,
    volume: 0.5,
    nowPlaying: {
        artist: '',
        title: '',
        cover: '',
        station: '',
        time: 0,
    },
};

export default function RadioReducer(state = initialState, action: any) {  // todo: typing
    switch (action.type) {
        case RADIO_STATION_SET: {
            return {...state, station: action.payload};
        }
        case RADIO_VOLUME_SET: {
            return {...state, volume: action.payload};
        }
        case RADIO_PLAYING_SET: {
            return {...state, isPlaying: action.payload};
        }
        case RADIO_NOW_PLAYING_SET: {
            return {...state, nowPlaying: action.payload};
        }
        default:
            return state;
    }
}
