import React from 'react';
import { useSelector } from 'react-redux';
import { STATIONS } from '../../constants';
import * as Sentry from '@sentry/browser';
import './style.scss';

interface IAdProps {
    url: string;
    image: string;
}

function Ad({ url, image }: IAdProps) {
    return (
        <div className="img">
            <a href={url} target="_blank" rel="noreferrer">
                <img src={image} alt="pic" />
            </a>
        </div>
    );
}

export default function Ads() {
    const [ads, setAds] = React.useState([]);
    const station = useSelector((store: any) => store.radio.station);
    
    React.useEffect(() => {
        const STATION_KEYS = Object.keys(STATIONS);
        const key = STATION_KEYS.indexOf(station.toLowerCase());

        if (key === -1) return;

        fetch(`${process.env.REACT_APP_API_URL}/ads`)
            .then((resp: any) => resp.json())
            .then((data: any) => setAds(data[key]))
            .catch((err: any) => Sentry.captureException(err)); // todo: sentry
    }, [station]);
    
    if (!ads || ads.length === 0) return null;

    return (
        <>
            {ads.map((ad: any, index: number) => <Ad key={index} url={ad.url} image={ad.image} />)}
        </>
    );
}
