import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import './style.scss';

import { STATIONS } from '../../constants';
import { setStation } from '../../store/actions';

interface IStationProps {
    station: any;
    selected: boolean;
    onSelect: () => void;
}

function Station({ station, selected, onSelect }: IStationProps) {
    const dispatch = useDispatch()

    function onClick() {
        dispatch(setStation(station.name));
        onSelect();
    }

    let stationName = station.name.toLowerCase();
    if (stationName === 'classic') {
        stationName = '';
    }

    return (
        <Link
            to={`/${stationName}`}
            onClick={onClick}
            className={`stations__item ${selected ? 'stations__item--selected' : ''}`}
        >
            <div className="stations__item__name">{station.name}</div>
            <div className="stations__item__desc">{station.description}</div>
        </Link>
    );
}

export default function Stations() {
    const station: string = useSelector((store: any) => store.radio.station); // todo: typing 
    const [isOpen, setOpen] = React.useState(false);

    function onSelectStation() {
        setOpen(false);
    }

    return (
        <div className={`stations ${isOpen ? 'open' : ''}`}>
            <div className="stations__topbar">
                <div className="stations__topbar-selected">
                    <div className="stations__topbar__name">{station}</div>
                    <div className="stations__topbar__desc">{(STATIONS as any)[station.toLowerCase()].description}</div>
                </div>
                <div className="stations__topbar-menu" onClick={() => setOpen(!isOpen)}>
                    <div className="navicon"></div>
                </div>
            </div>
            <div className="stations__container">
                {Object.values(STATIONS).map((s: any, index: number) => {
                    const isSelected = s.name === station;
                    return <Station key={s.name} station={s} selected={isSelected} onSelect={onSelectStation} />;
                })}
            </div>
        </div>
    );
}
