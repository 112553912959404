import React from 'react';
import { Helmet } from 'react-helmet';

import './styles.scss';

import HugGif from '../assets/hug.gif';

/* eslint-disable react/prefer-stateless-function */
export default class ThankYou extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Thank you | Radio NULA</title>
                </Helmet>

                <img className="thank-you-image" alt="hug" src={HugGif}/>

                <div className="thank-you-content">
                    <p>Thank you super much!!</p>
                    <p>*✧:･ﾟ♬♪ ٩(^‿^)۶ ♪♬*:･ﾟ✧*</p>
                </div>
            </React.Fragment> 
        );
    }
}
