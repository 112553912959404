import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './styles.scss';

import DonateImg from '../assets/donate-image.png';

/* eslint-disable react/prefer-stateless-function */
export default class Donation extends React.PureComponent {
  handleClick() {
    const form = document.getElementById('paypal') as HTMLFormElement;
    if (form) form.submit();
  }

  render() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Donate to Radio NULA</title>
            </Helmet>

            <img className="donation-img" src={DonateImg} alt="donation" />
            
            <div className="donation-content">
            
                <div className="donation-header">
                    <h1>Why donate?</h1>
                </div>

                <p>
                    As you can probably tell Radio NULA is a labor of love, but there
                    are still many expenses involved in broadcasting and updating our
                    ever expanding music library.
                </p>
                <p>
                    Donations, large or small, are very much needed and appreciated. If
                    you’ve been inspired and nourished by our musical offering and you
                    feel moved to donate, we would greatly appreciate it.
                </p>
                <p>
                    We are currently accepting <b>Paypal</b> donations.
                </p>

                <form
                    action="https://www.paypal.com/cgi-bin/webscr"
                    method="post"
                    target="_blank"
                    id="paypal"
                >
                    <input type="hidden" name="cmd" value="_s-xclick" />
                    <input
                    type="hidden"
                    name="hosted_button_id"
                    value="QY6UA4Y8E3PSN"
                    />
                    <div className="donation-paypal-btn" onClick={this.handleClick} />
                </form>

                <p>Thank you super much!! *✧:･ﾟ♬♪ ٩(^‿^)۶ ♪♬*:･ﾟ✧*</p>

                <div style={{ textAlign: 'right', marginTop: '3em' }}>
                   <Link to="/">Back to NULA</Link>
                </div>
            </div>
        </React.Fragment>    
    );
  }
}
