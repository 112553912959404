import React from 'react';
import { useSelector } from 'react-redux';
import Stations from '../components/stations';
import Player from '../components/player';
import Container from '../components/Container';
import Playlist from '../components/playlist';

export default function RadioStation() {
    const station = useSelector((state: any) => state.radio.station); // todo: typing

    return (
        <div className={`hero hero--${station.toLowerCase()}`}>
             <Stations />
             <Container>
                 <Player />
                 <Playlist />
             </Container>
         </div>
    );
}
