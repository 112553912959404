import React from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

import DonateImg from '../../assets/donate-image.png';

export default function Donation() {
    return (
        <Link to="/donate" target="_blank" className="donation-link">
            <div className="donation-container">
                <div className="donation-container__inner">
                    <div className="donation-container__banner">
                        <img className="donation-container__banner-image" alt="donation" src={DonateImg} />
                        <div className="donation-container__content">
                            <div className="donation-container__content__title">
                                Please consider donating and help us keep Radio NULA ads free.
                            </div>
                            <div className="donation-container__content__cta">Donate</div>
                            <p>We are currently accepting Paypal donations.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}