import { HISTORY_SET, HISTORY_ADD } from '../constants';

const initialState:any = []; // todo: typing

export default function HistoryReducer(state = initialState, action: any) { // todo: typing
    switch (action.type) {
        case HISTORY_SET: {
            return action.payload;
        }
        case HISTORY_ADD: {
            const copyState = [...state];
            // we want to maintain the same number of items in history, so every time we add an item, we have to remove one
            // copyState.pop();
            return [action.payload, ...copyState];
        }
        default:
            return state;
    }
}
