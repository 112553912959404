import React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';
import './style.scss';

import Song from '../song';
import { setNowPlaying, setHistory } from '../../store/actions';
import { NEW_TO_OLD } from '../../constants';

export default function Playlist() {
    const dispatch = useDispatch();
    const nowPlayingSong = useSelector((state: any) => state.radio.nowPlaying); // todo: typing
    const history = useSelector((state: any) => state.history); // todo: typing
    const station: string = useSelector((state: any) => state.radio.station); // todo: typing
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/playlist`)
            .then(resp => resp.json())
            .then(data => {
                const st = NEW_TO_OLD[station];
                if (data[st]) {
                    const currentSong = {...data[st].currentSong, time: new Date()};
                    dispatch(setHistory(data[st].history));
                    dispatch(setNowPlaying(currentSong));
                }
            })
            .catch(err => Sentry.captureException(err));
    }, [station, dispatch])

    return (
        <div className="playlist">
            <h2>Now Playing</h2>
            <div className="playlist__songs">
                <Song
                    artist={nowPlayingSong.artist}
                    title={nowPlayingSong.title}
                    cover={nowPlayingSong.cover}
                />
            </div>

            <h2>Previously played</h2>
            <div className="playlist__songs playlist__songs--history">
                {history.map((s: any, index: number) => {
                    return (
                        <Song
                            key={index}
                            artist={s.artist}
                            title={s.title}
                            cover={s.cover}
                        />
                    )
                })}
            </div>
        </div>
    );
}
