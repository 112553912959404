import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/browser';
import { io } from "socket.io-client";

export default function useSocket() {
    const ref = useRef<any>();

    useEffect(() => {
        if (!process.env.REACT_APP_WEBSOCKET) {
            Sentry.captureException('No websocket defined.');
            return;
        }
        const socket = io(process.env.REACT_APP_WEBSOCKET);
        socket.on('connect_error', (err: any) => { // todo: typing
            Sentry.captureException(err);
        });

        ref.current = socket;

        return () => {
            socket.disconnect();
        }
    }, []);

    return ref.current
}
