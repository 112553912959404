export const STATIONS = {
    classic: {
        name: 'Classic',
        description: 'Soul - Funk - Disco - Hip-Hop and all that Jazz',
        stream: 'https://strm.radionula.com/classics',
    },
    organic: {
        name: 'Organic',
        description: 'Mostly organic Soul - Funk - Disco - Jazz',
        stream: 'https://strm.radionula.com/channel2',
    },
    beatz: {
        name: 'Beatz',
        description: 'Dusty, smoky beats to zone out and chill',
        stream: 'https://strm.radionula.com/lounge',
    },
    //bassic: {
    //    name: 'Bassic',
    //    description: `Bass heavy, mostly Drum'n'Bass`,
    //    stream: 'https://strm.radionula.com/lounge',
    //},
};

export const NEW_TO_OLD: any = {
    Classic: 'classics',
    Organic: 'ch2',
    Beatz: 'smoky',
    Bassic: 'bassic',
};

export const OLD_TO_NEW: any = {
    classics: 'Classic',
    ch2: 'Organic',
    smoky: 'Beatz',
    bassic: 'Bassic',
};
