import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';

import './reset.css';
import './index.scss';

import store from './store';
import App from './App';
//import reportWebVitals from './reportWebVitals';

import hd1 from './assets/img-hero-desktop-01-classic.jpg';
import hd2 from './assets/img-hero-desktop-02-organic.jpg';
import hd3 from './assets/img-hero-desktop-03-beatz.jpg';
import hd4 from './assets/img-hero-desktop-04-bassic.jpg';

import hm1 from './assets/img-hero-mobile-01-classic.jpg';
import hm2 from './assets/img-hero-mobile-02-organic.jpg';
import hm3 from './assets/img-hero-mobile-03-beatz.jpg';
import hm4 from './assets/img-hero-mobile-04-bassic.jpg';

import play from './assets/img-player-play.png';
import stop from './assets/img-player-stop.png';
import next from './assets/img-player-next-channel.png';

import radioNoise from './assets/radionoise.mp3';

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

ReactGA.initialize('UA-1132265-3');

// preload some assets
Promise.all([radioNoise, hd1, hd2, hd3, hd4, hm1, hm2, hm3, hm4, play, stop, next])
    .then((data: any) => console.log('images preloaded'))
    .catch((err: any) => Sentry.captureException(err));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
