import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Howl, Howler } from 'howler';
import { useLocation } from 'react-router-dom';

import './style.scss';

import radioNoise from '../../assets/radionoise.mp3';
import { setVolume, play, stop, setStation, setNowPlaying, addHistory } from '../../store/actions';
import { STATIONS, OLD_TO_NEW } from '../../constants';
import useSocket from '../../hooks/socket';

const STATION_KEYS: string[] = Object.keys(STATIONS);

export default function Radio() {
    const socket = useSocket();
    const dispatch = useDispatch();
    const store = useStore();
    const [socketConnected, setSocketConnected] = useState(false);
    const volume = useSelector((store: any) => store.radio.volume); // todo: typing
    const isPlaying = useSelector((store: any) => store.radio.isPlaying); // todo: typing
    const station = useSelector((store: any) => store.radio.station); // todo: typing
    const nowPlaying = useSelector((store: any) => store.radio.nowPlaying); // todo: typing
    
    const location = useLocation();

    useEffect(() => {
        if (!socket) return;
        if (socketConnected) return;
        
        setSocketConnected(true); 
        
        socket.on('songChange', (data: any) => { // todo: typing
            const state = store.getState();
            
            const station = state.radio.station;
            const channel = OLD_TO_NEW[data.channel];
            if (station !== channel) return;
            
            const time = new Date();
            const song = {...data.song, station: channel, time};
            const nowPlaying = state.radio.nowPlaying;
            
            dispatch(addHistory(nowPlaying));
            dispatch(setNowPlaying(song));
        });
    }, [socket, store, socketConnected, dispatch]);

    useEffect(() => {
        if (STATION_KEYS.includes(location.pathname.replace('/', ''))) {
            let stationName = location.pathname.replace('/', '');
            if (stationName === '') {
                stationName = 'classic';
            }
            stationName = stationName[0].toUpperCase() + stationName.slice(1);
            dispatch(setStation(stationName))
        }
    }, [location, dispatch]);

    let howler: any = React.useRef();
    let audioNoise: any = React.useRef();

    useEffect(() => {
        if (!audioNoise.current) {
            audioNoise.current = new Howl({
                src: radioNoise,
                format: ['mp3'],
            });
        }

        if (howler.current) {
            howler.current.unload();
            howler.current.stop();
        }

        howler.current = new Howl({
            src: (STATIONS as any)[station.toLowerCase()].stream,
            html5: true,
            format: ['mp3'],
            onplayerror: () => {
                howler.current.once('unlock', () => howler.current.play());
            },
        });
        howler.current.on('play', () => {
            audioNoise.current.stop();
        });
        Howler.autoUnlock = false;

        if (isPlaying) {
            howler.current.play();
            if (!audioNoise.current.playing()) {
                audioNoise.current.play();
            }
        }
    }, [station, isPlaying]);

    function onVolumeChange(e: any) { // todo: typing
        if (!howler.current) return;
        Howler.volume(e.target.value); // change volume globally
        dispatch(setVolume(e.target.value));
    }
    
    function onPlay() {
        if (!howler.current) return;
        audioNoise.current.play();
        howler.current.play();
        dispatch(play());
    }
    
    function onStop() {
        if (!howler.current) return;
        audioNoise.current.stop();
        howler.current.unload();
        howler.current.stop();
        dispatch(stop());
    }

    function onNext() {
        let currentStationIndex = STATION_KEYS.indexOf(station.toLowerCase());
        if (currentStationIndex === -1) {
            return;
        }

        let newStationIndex = currentStationIndex + 1;
        if (newStationIndex >= STATION_KEYS.length) {
            newStationIndex = 0;
        }
        const newStation = (STATIONS as any)[STATION_KEYS[newStationIndex]].name;
        dispatch(setStation(newStation)); 
    }
    
    let coverStyle: any = {};
    if (isPlaying && nowPlaying.cover) {
        coverStyle.backgroundImage = `url("${encodeURI(nowPlaying.cover.replace('http://185.148.72.93', 'https://media.radionula.com'))}")`;
    }

    return (
        <div className="radio">
            <div className="radio__machine">
                <div className="radio__machine__logo"></div>
                <div className="radio__machine__controls">
                    {isPlaying && (
                        <>
                            <div className="radio__machine__next" onClick={onNext}></div>
                            <div className="radio__machine__stop" onClick={onStop}></div>
                        </>    
                    )}
                    {!isPlaying && (
                        <div className="radio__machine__play" onClick={onPlay}></div>
                    )}
                </div>
                {isPlaying && (
                    <div className="radio__machine__volume">
                        <input type="range" min="0.0" max="1.0" step="0.01" value={volume} onChange={onVolumeChange} />
                        <div className="radio__machine__volume-line"></div>
                    </div>
                )}
            </div>
            <div className="radio__record">
                <div className="radio__record-vinyl">
                    <div className={`radio__record-cover ${isPlaying ? 'radio__record-rotate' : ''}`} style={coverStyle}></div> 
                    <div className="radio__record-circle"></div>
                </div>
            </div>
        </div>
    );
}
