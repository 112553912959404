import {
    RADIO_STATION_SET,
    RADIO_VOLUME_SET,
    RADIO_PLAYING_SET,
    RADIO_NOW_PLAYING_SET,

    HISTORY_SET,
    HISTORY_ADD,
} from './constants';

export function setStation(station: any) { // todo: typing
    return { type: RADIO_STATION_SET, payload: station };
}

export function setVolume(volume: any) { // todo: typing
    return { type: RADIO_VOLUME_SET, payload: volume };
}

export function play() {
    return { type: RADIO_PLAYING_SET, payload: true };
}

export function stop() {
    return { type: RADIO_PLAYING_SET, payload: false };
}

export function setNowPlaying(song: any) { // todo: typing
    return { type: RADIO_NOW_PLAYING_SET, payload: song };
}

export function setHistory(songs: any[]) {
    return { type: HISTORY_SET, payload: songs};
}

export function addHistory(song: any) {
    return { type: HISTORY_ADD, payload: song };
}
