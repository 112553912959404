import React from 'react';
import moment from 'moment';
import './style.scss';

interface ISongProps {
    artist: string;
    title: string;
    cover: string;
    time?: Date;
}

export default function Song({ artist, title, cover, time }: ISongProps) {
    const imgUrl = encodeURI(cover.replace('http://185.148.72.93', 'https://media.radionula.com'));
    const style = {
        backgroundImage: `url("${imgUrl}")`,
        backgroundColor: 'rgba(0, 0, 0, 0.65)',
    };
    return (
        <div className="song">
            <div className="song__cover" style={style}></div>
            <div className="song__name">{artist} - {title}</div>
            {time && <div className="song__time">{moment(time).format('HH:mm')}</div>}
        </div>
    );
}
