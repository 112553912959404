import React from 'react';
import { Link } from 'react-router-dom';

import image404 from '../assets/404.gif';
import logo from '../assets/img-nula-radio-logo.png';

export default function NotFound() {
    return (
        <div style={{ display: 'flex', width: '100%', height: '100%', flexFlow: 'column' }}>
            <div style={{ margin: 'auto', display: 'flex', flexFlow: 'column' }}>
                <img src={logo} alt="logo" style={{ width: '100%', maxWidth: '300px', margin: '0 auto 3em' }} />
                <img src={image404} alt="404" style={{ width: '100%', maxWidth: '500px' }} />
                <p style={{ color: 'rgb(177 178 178)', textAlign: 'center', marginTop: '3em' }}>
                    Nothing is here, <Link to="/">go home</Link>.
                </p>
            </div>
        </div>
    );
}