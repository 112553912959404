// @ts-nocheck
import React from 'react';
import { Route, Switch } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import RadioStation from './containers/RadioStation';
import shop from './assets/shop.png';
import Container from './components/Container';
import DonationBanner from './components/donation';
import Ads from './components/ads';

import DonationPage from './pages/donation';
import ThankYouPage from './pages/thank-you';
import NotFound from './pages/404';

import { FacebookProvider, Comments } from 'react-facebook';

function usePageViews() {
    const location = useLocation();
    React.useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
}

function App() {
    usePageViews();

    return (
        <Switch>
            <Route exact path={['/', '/classic', '/organic', '/beatz', '/bassic']}>
                <RadioStation />
                <DonationBanner />
                <Container>
                    <div className="facebook-wrapper"> 
                        <FacebookProvider appId="330582393726734">
                            <Comments
                                href="http://radionula.com/"
                                orderBy="reverse_time"
                                numPosts="8"
                                width="100%"
                            />
                        </FacebookProvider>
                    </div>
                    <div className="imgs-wrapper">
                        <div className="img">
                            <a href="https://www.redbubble.com/people/radionula/shop" target="_blank" rel="noreferrer">
                                <img src={shop} alt="shop" className="no-borders" />
                            </a>
                        </div>
                        <Ads />
                    </div>
                </Container>
            </Route>
            <Route exact path={['/donate']}>
                <DonationPage />
            </Route>
            <Route exact path={['/thank-you', '/thankyou', '/thankyou.html']}>
                <ThankYouPage />
            </Route>
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}

export default App;
